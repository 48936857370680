import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import StyledHero from '../components/StyledHero'
import CalImg from '../images/calculator-header.jpg';
import Banner from '../components/Banner';
import { GoLinkExternal } from 'react-icons/go';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';



export default function Documents() {

    return (
        <div id="calculatorsWrapper">
            <Helmet>
                <title>Calculadoras</title>
                <meta name="description" content="Using a mortgage calculator is a good way to see what your financing options are and how much you can afford before actually applying for a loan." />
            </Helmet>
            <StyledHero img={CalImg}>
                <Banner title="Calculadoras de hipotecas">
                </Banner>
            </StyledHero>            
            <Container>
                <Row>
                    <Col className="p-5" lg={12}>                                                                    
                        <h3 className="sun"><strong>Calculadoras para Ayudarte a Decidir</strong></h3>
                        <p>Utilizar una calculadora hipotecaria es una buena forma de ver tus opciones de financiamiento y cuánto puedes pagar antes de solicitar un préstamo. Una vez compares y entiendas tus opciones, el proceso es simple, solo haz clic aquí: <Link to='./Prequal'>PRE-CALIFICACIÓN</Link></p>

                        <h4 className="sun"><a href="https://www.swmc.com/angelai/calculators" target="_blank" rel="noopener noreferrer">Calculadora de hipoteca de tasa fija&nbsp;<GoLinkExternal /></a></h4>

                        <h4 className="sun"><a href="https://www.swmc.com/angelai/calculators" target="_blank" rel="noopener noreferrer">Calculadora de Refinanciamiento&nbsp;<GoLinkExternal /></a></h4>

                        <h4 className="sun"><a href="https://www.swmc.com/angelai/calculators" target="_blank" rel="noopener noreferrer">Calculadora de Asequibilidad&nbsp;<GoLinkExternal /></a></h4>

                        <h4 className="sun"><a href="https://www.swmc.com/angelai/calculators" target="_blank" rel="noopener noreferrer">Calculadora de Alquiler vs. Compra&nbsp;<GoLinkExternal /></a></h4>

                     

                        <h4 className="sun"><a href="https://sunwestmortgage.com/biweekly-calculator.php" target="_blank" rel="noopener noreferrer">Calculadora de Pago Quincenal&nbsp;<GoLinkExternal /></a></h4>
                    </Col>
                </Row>
            </Container>              
        </div>
    )
}
