import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { CookieSetting } from "cookiesetting-component";

const root = createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <App />
        <CookieSetting
            privacyPolicyLink={process.env.REACT_APP_PRIVACY_POLICY_LINK}
            configUrl={process.env.REACT_APP_COOKIE_SETTING_JSON}
            analytics={process.env.REACT_APP_ANALYTICS_PATH}
        />
    </React.StrictMode>
);
