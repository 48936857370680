import React from 'react';
import NavDesktop from '../NavDesktop';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';
import UserWayAccessibilityMenu from "../../UserWayAccessibilityMenu/index";
import { Link } from 'react-router-dom';


import DrawerToggleButton from '../SideDrawer/DrawerToggleButton';
import './Toolbar.css';

const toolbar = props => (
    <>
        <header className="toolbar">
            <nav className='toolbar_nav'>
                <Navbar.Brand className="mr-sm-4 mobileLogo" as={Link} to="https://www.swmc.com/" target="_blank" rel="noopener noreferrer">
                    <div className="d-flex accessibilitychanges">
                        <div><a
                            href="https://www.swmc.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Sun West Mortgage Logo"
                        >
                            <Image className="img-fluid" srcSet="./assest/logo-sm.png 250w, 
                                ./assest/logo-md.png 350w, 
                                ./assest/logo-business.png 456w"
                                sizes="(max-width: 580px) 250px,
                                       (max-width: 780px) 350px,
                                        456px"
                                src="./assest/logo-business.png" alt="Sun West Mortgage Company, Inc. Logo" />
                        </a></div>
                        {/* <UserWayAccessibilityMenu color="white"  /> */}
                    </div>
                </Navbar.Brand>
                <div className="toolbar_toggle-button">

                    <DrawerToggleButton click={props.drawerClickHandler} />
                </div>
                <NavDesktop setModalShow={props.setModalShow} />
            </nav>
        </header>
        <div className="mobile-AccessibilityMenu-show">
            <UserWayAccessibilityMenu color="white" width={40} height={40} />
        </div>
    </>
);

export default toolbar;